$(document).ready(function() {
 
  if($('#owl-home').length){
    $('#owl-home').owlCarousel({
        navigation: false, // Show next and prev buttons
        slideSpeed: 300,
        paginationSpeed: 400,
        singleItem: true,
        autoPlay: 7000,
    });    
  }

  if($('#owl-falecidos').length){
    $('#owl-falecidos').owlCarousel({
      itemsCustom: [
        [0, 1],
        [450, 1],
        [600, 2],
        [700, 2],
        [1000, 3],
        [1200, 3],
        [1400, 4],
        [1600, 4]
      ],
      navigation: true,
      pagination: false,
      autoPlay: 10000,
      navigationText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    });    
  }
 
  if($('#owl-parceiros').length){
    $('#owl-parceiros').owlCarousel({
      itemsCustom: [
        [0, 1],
        [450, 1],
        [600, 2],
        [700, 3],
        [1000, 4],
        [1200, 5],
        [1400, 6],
        [1600, 5]
      ],
      navigation: true,
      pagination: false,
      autoPlay: 10000,
      navigationText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    });    
  }

  if($('.fancybox').length){
    $('.fancybox').fancybox({
      openEffect  : 'none',
      closeEffect : 'none'
    });
  }

  if($('#map').length){
    var datalat     = $('#map').attr('data-lat');
    var datalong    = $('#map').attr('data-long');
    var datazoom    = parseInt($('#map').attr('data-zoom'));
    var datascroll = false;
    if($('#map').attr('data-scroll') == 'true'){
      datascroll = true;
    }

    // When the window has finished loading create our google map below
    google.maps.event.addDomListener(window, 'load', init);

    function init() {
      // Basic options for a simple Google Map
      // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
      var myLatlng = new google.maps.LatLng(datalat, datalong);

      var mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: datazoom,
        disableDefaultUI: true,
        scrollwheel: datascroll,

        // The latitude and longitude to center the map (always required)
        center: myLatlng, // New York

        // How you would like to style the map. 
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{"featureType":"landscape","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"stylers":[{"hue":"#00aaff"},{"saturation":-100},{"gamma":2.15},{"lightness":12}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"lightness":24}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":57}]}]
      };

      // Get the HTML DOM element that will contain your map 
      // We are using a div with id="map" seen below in the <body>
      var mapElement = document.getElementById('map');

      // Create the Google Map using out element and options defined above
      var map = new google.maps.Map(mapElement, mapOptions);

      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        icon: 'assets/theme/front/images/common/pin.png',
        title: 'Consoni Assistência Familair'
      });
    }
  }

  if($('.form-ajax').length){

    $(".form-ajax").on("submit", function(e) {
      var form = $(this);
      var action = form.attr('action');
      var method = form.attr('method');
      var reset = form.attr('data-reset');

      $('.rtnForm').html('<i class="fa fa-spinner fa-spin fa-fw"></i> Aguarde enviando...');

      $.ajax({
        url: action,
        type: method,
        dataType: 'json',
        data: form.serialize(),
        success : function(json){
          if(json.class == 'alert-success') {
            $('.rtnForm').html(json.message);
            $('.rtnForm').addClass('alert-success')
            $('.rtnForm').removeClass('alert-danger')
            if(reset == 'reset'){
              form.get(0).reset();
            }
          } else {
            $('.rtnForm').html(json.message);
            $('.rtnForm').removeClass('alert-success')
            $('.rtnForm').addClass('alert-danger')
          }
        },
        error : function(retorno){
          console.log(retorno);
        }
      });

      // cansela o submit pra não sair da página
      return false;
    });

  }

  if($('input[data-inputmask]').length){
    $('input[data-inputmask]').inputmask();
  }

  if($('.btnComentar').length && $('.boxComentar').length){
    $('.btnComentar').on('click', function(){
      $('.boxComentar').removeClass('hidden');
    });
  }

});